/**
 * Try to use high performance GPU on dual-GPU systems
 */
runOnHighPerformanceGPU();

/**
 * Use passive listeners to improve scrolling performance
 */
jQuery.event.special.touchstart = {
	setup: function (_, ns, handle) {
		if (ns.includes('noPreventDefault')) {
			this.addEventListener('touchstart', handle, {
				passive: false
			});
		} else {
			this.addEventListener('touchstart', handle, {
				passive: true
			});
		}
	}
};

jQuery.event.special.touchend = {
	setup: function (_, ns, handle) {
		if (ns.includes('noPreventDefault')) {
			this.addEventListener('touchend', handle, {
				passive: false
			});
		} else {
			this.addEventListener('touchend', handle, {
				passive: true
			});
		}
	}
};

/**
 * Extend default easing functions set
 */
jQuery.extend(jQuery.easing, {
	easeInOutExpo: function (x, t, b, c, d) {
		if (t == 0) return b;
		if (t == d) return b + c;
		if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
		return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
	}
});

/**
 * GSAP: turn off console warnings
 */
gsap.config({
	nullTargetWarn: false
});

/**
 * Global Vars
 */
window.$document = $(document);
window.$window = $(window);
window.$body = $('body');
window.$html = $('html');
window.$spinner = $('#js-spinner');
window.$barbaWrapper = $('[data-barba="wrapper"]');
window.$pageWrapper = $('#page-wrapper');
window.$pageContent = $('.page-wrapper__content');
window.$pagePreloader = $('#js-preloader');
window.PagePreloader = new Preloader({
	scope: window.$document,
	target: window.$pagePreloader,
	curtain: {
		element: $('#js-page-transition-curtain'),
		background: $('.section-masthead').attr('data-background-color')
	},
	counter: {
		easing: 'power4.out',
		duration: 25,
		start: 0,
		target: 100,
		prefix: '',
		suffix: ''
	}
});

/**
 * Begin Page Load
 */
window.PagePreloader.start();

/**
 * Default Theme Options
 * Used to prevent errors if there is
 * no data provided from backend
 */
if (typeof window.theme === 'undefined') {
	window.theme = {
		ajax: {
			enabled: true,
			preventRules: '' // jQuery selectors of the elements to exclude them from AJAX transitions
		},
		animations: {
			triggerHook: 0.85, // more info https://scrollmagic.io/docs/ScrollMagic.Scene.html#triggerHook
			timeScale: {
				onScrollReveal: 1, // on-scroll animations global speed
				overlayMenuOpen: 1, // fullscreen menu open speed
				overlayMenuClose: 1, // fullscreen menu close speed
			}
		},
		cursorFollower: {
			enabled: true,
			labels: {
				slider: 'Drag'
			}
		},
		smoothScroll: { // more info https://github.com/idiotWu/smooth-scrollbar/tree/develop/docs
			enabled: true,
			damping: 0.12,
			renderByPixels: true,
			continuousScrolling: false,
			plugins: {
				edgeEasing: true
			}
		},
		contactForm7: {
			customModals: true
		}
	}
}

/**
 * ScrollMagic Setup
 */
window.SMController = new ScrollMagic.Controller();
window.SMController.enabled(false); // don't start animations yet
window.SMSceneTriggerHook = window.theme.animations.triggerHook;
window.SMSceneReverse = false;

/**
 * Don't save scroll position
 * after AJAX transition
 */
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}

/**
 * Page Load Strategy
 */
window.$window.on('load', function () {

	new Animations();

	// load fonts first
	document.fonts.ready
		// prepare all the texts
		.then(() => SetText.splitText({
			target: window.$document.find('.js-split-text')
		}))
		.then(() => SetText.setLines({
			target: window.$document.find('.split-text[data-split-text-set="lines"]')
		}))
		.then(() => SetText.setWords({
			target: window.$document.find('.split-text[data-split-text-set="words"]')
		}))
		.then(() => SetText.setChars({
			target: window.$document.find('.split-text[data-split-text-set="chars"]')
		}))
		// init template components
		.then(() => {
			initComponentsOnce({
				scope: window.$document
			});

			initComponents({
				scope: window.$document
			});
		})
		.then(() => window.PagePreloader.finish())
		.then(() => {
			// init cursor only on non-touch browsers
			if (window.theme.cursorFollower.enabled && !window.Modernizr.touchevents) {
				new Cursor({
					scope: window.$document,
					target: $('#js-cursor'),
					cursorElements: '[data-arts-cursor]',
					highlightElements: 'a:not(a[data-arts-cursor]):not(.social__item a):not(.section-video__link):not(.no-highlight), button:not(button[data-arts-cursor]), .filter__item, .section-nav-projects__header', // links to highlight
					highlightScale: 1.5, // default highlight scaling
					magneticElements: '[data-arts-cursor-magnetic]', // magnetic elements 
					magneticScaleCursorBy: 1.3, // default magnetic scaling
					animDuration: 0.25,
				});
			}
			// begin animations 
			window.SMController.enabled(true);
			window.SMController.update(true);
		});

	// init AJAX navigation
	if (window.theme.ajax.enabled) {
		new PJAX({
			target: window.$barbaWrapper,
			scope: window.$document
		});
	}

});

/**
 * Init Template Components after the initial
 * load and after an AJAX transition happens.
 * 
 * You can init your custom scripts here
 * in that function
 */
function initComponents({
	scope = window.$document,
	container = window.$pageWrapper,
	scrollToHashElement = true
}) {

	// init page header one time only
	if (typeof window.theme.header === 'undefined') {
		window.theme.header = new Header();
	}

	new MobileBarHeight();
	new SmoothScroll({
		target: container.filter('.js-smooth-scroll'),
		adminBar: $('#wpadminbar'),
		absoluteElements: $('[data-arts-scroll-absolute]'), // correct handling of absolute elements OUTSIDE scrolling container
		fixedElements: $('[data-arts-scroll-fixed]') // correct handling of fixed elements INSIDE scrolling container
	});
	new ScrollDown({
		target: scope.find('[data-arts-scroll-down]'),
		scope,
		duration: 0.8
	})
	new ArtsParallax({
		target: scope.find('[data-arts-parallax]'),
		factor: 0.3,
		ScrollMagicController: window.SMController,
		SmoothScrollBarController: window.SB
	});
	new AsideCounters({
		target: scope.find('.aside-counters'),
		scope
	});
	new Arrow({
		target: scope.find('.js-arrow')
	});
	new SectionMasthead({
		target: scope.find('.section-masthead'),
		scope
	});
	new SectionContent({
		target: scope.find('.section-content'),
		scope
	});
	new SectionProjectsSlider({
		target: scope.find('.section-projects-slider'),
		scope
	});
	new SectionList({
		target: scope.find('.section-list'),
		scope
	});
	new ChangeTextHover({
		target: scope.find('.js-change-text-hover:not(.js-change-text-hover .js-change-text-hover)'), // exclude nested elements
		scope,
		pageIndicator: scope.find('.js-page-indicator'), // fixed page indicator
		triggers: scope.find('.js-page-indicator-trigger'), // elements that triggers the change of page indicator
	});
	new PageIndicator(scope);
	new PSWPGallery({
		target: scope.find('.js-gallery'),
		scope,
		options: { // Pass your custom PhotoSwipe options here https://photoswipe.com/documentation/options.html
			history: window.theme.ajax.enabled ? false : true, // galleries URLs navigation is NOT compatible with AJAX
			showAnimationDuration: 300,
		}
	});
	new PSWPAlbum({
		target: scope.find('.js-album'),
		scope,
		options: { // Pass your custom PhotoSwipe options here https://photoswipe.com/documentation/options.html
			history: window.theme.ajax.enabled ? false : true, // galleries URLs navigation is NOT compatible with AJAX
			showAnimationDuration: 300,
		}
	});
	new GMap({
		target: scope.find('.js-gmap'),
		scope
	});
	new Form({
		target: scope,
		scope
	});
	new FormAJAX({
		target: scope.find('.js-ajax-form'),
		scope
	});
	new SectionSliderImages({
		target: scope.find('.section-slider-images'),
		scope
	});
	new SectionTestimonials({
		target: scope.find('.section-testimonials'),
		scope
	});
	new SectionGrid({
		target: scope.find('.section-grid'),
		scope
	});

	new SectionNavProjects({
		target: scope.find('.section-nav-projects'),
		scope
	});

	new CircleButton({
		target: scope.find('.js-circle-button:not(.js-circle-button_curved)'),
		scope
	});

	new SectionScroll({
		target: scope.find('.section-scroll'),
		scope
	});

	// scroll to anchor from URL hash
	if (scrollToHashElement) {
		Scroll.scrollToAnchorFromHash(2600);
	}

	//
	// your custom plugins init here
	//

	console.log(container.data("barba-namespace"));
	
	if (container.data("barba-namespace") == "home") {
		// stop scrolling and swiping

		const optionsFullEl = {
			root: null, // Use the viewport as the root
			rootMargin: '0px', // No margin around the viewport
			threshold: 0.8, // % of the element must be in view
		};

		const processSwiperContainer = document.getElementById('processSwiperContainer');

		function handleIntersectionprocessContainer(entries, observerprocessContainer) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
			//console.log('Container Element is in view!');

			processSwiperContainer.classList.remove('pointer-events-none-desktop');

			if (window.innerWidth >= 900) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
				window.SB.scrollTo(0, processSwiperContainer.getBoundingClientRect().top + scrollTop - 2, 300)
			}

			} else {
			//console.log('Container Element is out of view!');

			processSwiperContainer.classList.add('pointer-events-none-desktop');
			}
		});
		}

		const observerprocessContainer = new IntersectionObserver(handleIntersectionprocessContainer, optionsFullEl);
		observerprocessContainer.observe(processSwiperContainer);

		

		// start swipers on 0 whne scroll on it

		const options = {
		root: null, // Use the viewport as the root
		rootMargin: '0px', // No margin around the viewport
		threshold: 0.1, // % of the element must be in view
		};

		const processSwiperElement = document.getElementById('processSwiper');

		function handleIntersectionprocess(entries, observerprocess) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
			//console.log('Element is in view!');

			var processSwiper = processSwiperElement;
			processSwiper.swiper.slideTo(0, 0);

			}
		});
		}

		const observerprocess = new IntersectionObserver(handleIntersectionprocess, options);
		observerprocess.observe(processSwiperContainer);

		/* fix of release horizontal scroll after start fire of last slide + html duplicate */

		processSwiperContainer.querySelector(".js-slider-fullscreen-projects__counter-total").innerText = "IV";

		processSwiperElement.swiper.on('slideChangeTransitionStart', function() {
		 if (processSwiperElement.swiper.activeIndex == 4) {
		  setTimeout(function() {
		   processSwiperElement.swiper.slideTo(3, 0);
		  }, 30);
		 }
		});
	  
		/* / */

	} else if (container.data("barba-namespace") == "residences") {

		// start swipers on 0 whne scroll on it
      
		const options = {
			root: null, // Use the viewport as the root
			rootMargin: '0px', // No margin around the viewport
			threshold: 0.1, // 50% of the element must be in view
		  };
		  
		  var cayoSwiperElement = document.getElementById('cayoSwiper');
	
		  function handleIntersectionCayo(entries, observerCayo) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var cayoSwiper = cayoSwiperElement;
				cayoSwiper.swiper.autoplay.stop();
				cayoSwiper.swiper.slideTo(1, 0);
				cayoSwiper.swiper.slideTo(0, 0);
				let swiperDots = cayoSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				cayoSwiper.swiper.autoplay.start();
			  }
			});
		  }
	
		  const observerCayo = new IntersectionObserver(handleIntersectionCayo, options);
		  observerCayo.observe(cayoSwiperElement);
	
		  ////////////////
	
		  const yachtSwiperElement = document.getElementById('yachtSwiper');
	
		  function handleIntersectionYacht(entries, observerYacht) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var yachtSwiper = yachtSwiperElement;
				yachtSwiper.swiper.autoplay.stop();
				yachtSwiper.swiper.slideTo(1, 0);
				yachtSwiper.swiper.slideTo(0, 0);
				let swiperDots = yachtSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				yachtSwiper.swiper.autoplay.start();
			  }
			});
		  }
	
		  const observerYacht = new IntersectionObserver(handleIntersectionYacht, options);
		  observerYacht.observe(yachtSwiperElement);
		  
		  ////////////////
	
		  const banksideSwiperElement = document.getElementById('banksideSwiper');
	
		  function handleIntersectionBankside(entries, observerBankside) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var banksideSwiper = banksideSwiperElement;
				banksideSwiper.swiper.autoplay.stop();
				banksideSwiper.swiper.slideTo(1, 0);
				banksideSwiper.swiper.slideTo(0, 0);
				let swiperDots = banksideSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				banksideSwiper.swiper.autoplay.start();
			  }
			});
		  }
	
		  const observerBankside = new IntersectionObserver(handleIntersectionBankside, options);
		  observerBankside.observe(banksideSwiperElement);

	} else if (container.data("barba-namespace") == "hotels") {

		// start swipers on 0 whne scroll on it
      
		const options = {
			root: null, // Use the viewport as the root
			rootMargin: '0px', // No margin around the viewport
			threshold: 0.1, // 50% of the element must be in view
		  };
		  
		  const rivieraSwiperElement = document.getElementById('rivieraSwiper');
	
		  function handleIntersectionriviera(entries, observerriviera) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var rivieraSwiper = rivieraSwiperElement;
				rivieraSwiper.swiper.autoplay.stop();
				rivieraSwiper.swiper.slideTo(1, 0);
				rivieraSwiper.swiper.slideTo(0, 0);
				let swiperDots = rivieraSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				rivieraSwiper.swiper.autoplay.start();
			  } else {
				//console.log('Element is out of view!');
			  }
			});
		  }
	
		  const observerriviera = new IntersectionObserver(handleIntersectionriviera, options);
		  observerriviera.observe(rivieraSwiperElement);
	
		  ////////////////
	
		  const kempinskySwiperElement = document.getElementById('kempinskySwiper');
	
		  function handleIntersectionkempinsky(entries, observerkempinsky) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var kempinskySwiper = kempinskySwiperElement;
				kempinskySwiper.swiper.autoplay.stop();
				kempinskySwiper.swiper.slideTo(1, 0);
				kempinskySwiper.swiper.slideTo(0, 0);
				let swiperDots = kempinskySwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				kempinskySwiper.swiper.autoplay.start();
			  } else {
				//console.log('Element is out of view!');
			  }
			});
		  }
	
		  const observerkempinsky = new IntersectionObserver(handleIntersectionkempinsky, options);
		  observerkempinsky.observe(kempinskySwiperElement);
	
		  ////////////////
	
		  const golfSwiperElement = document.getElementById('golfSwiper');
	
		  function handleIntersectiongolf(entries, observergolf) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var golfSwiper = golfSwiperElement;
				golfSwiper.swiper.autoplay.stop();
				golfSwiper.swiper.slideTo(1, 0);
				golfSwiper.swiper.slideTo(0, 0);
				let swiperDots = golfSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				golfSwiper.swiper.autoplay.start();
			  } else {
				//console.log('Element is out of view!');
			  }
			});
		  }
	
		  const observergolf = new IntersectionObserver(handleIntersectiongolf, options);
		  observergolf.observe(golfSwiperElement);
	
		  ////////////////
	
		  const almanacSwiperElement = document.getElementById('almanacSwiper');
	
		  function handleIntersectionalmanac(entries, observeralmanac) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var almanacSwiper = almanacSwiperElement;
				almanacSwiper.swiper.autoplay.stop();
				almanacSwiper.swiper.slideTo(1, 0);
				almanacSwiper.swiper.slideTo(0, 0);
				let swiperDots = almanacSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				almanacSwiper.swiper.autoplay.start();
			  } else {
				//console.log('Element is out of view!');
			  }
			});
		  }
	
		  const observeralmanac = new IntersectionObserver(handleIntersectionalmanac, options);
		  observeralmanac.observe(almanacSwiperElement);
	
		  ////////////////
	
		  const claridgesSwiperElement = document.getElementById('claridgesSwiper');
	
		  function handleIntersectionclaridges(entries, observerclaridges) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				//console.log('Element is in view!');
				var claridgesSwiper = claridgesSwiperElement;
				claridgesSwiper.swiper.autoplay.stop();
				claridgesSwiper.swiper.slideTo(1, 0);
				claridgesSwiper.swiper.slideTo(0, 0);
				let swiperDots = claridgesSwiperElement.parentElement.querySelectorAll(".circle");
				swiperDots.forEach(function(dots) {
					dots.style.strokeOpacity = 0;
				});
				claridgesSwiper.swiper.autoplay.start();
			  } else {
				//console.log('Element is out of view!');
			  }
			});
		  }
	
		  const observerclaridges = new IntersectionObserver(handleIntersectionclaridges, options);
		  observerclaridges.observe(claridgesSwiperElement);

	} else if (container.data("barba-namespace") == "offices") {

	} else if (container.data("barba-namespace") == "contact") {

	} else if (container.data("barba-namespace") == "privacy-policy") {

	} else if (container.data("barba-namespace") == "home") {

	} else {

		

	}


	// cookies

	function getCookie(name) {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
		  const cookie = cookies[i].trim();
		  if (cookie.startsWith(name + '=')) {
			return cookie.substring(name.length + 1);
		  }
		}
		return null; // Cookie not found
	  }

	  console.log("cookie: " + getCookie("cookiesAccept"));

	  $( document ).ready(function() {
		if (getCookie("cookiesAccept")) {
		} else {
		  $('#cookiesModal').modal('show')
		}
	  });

		const currentDate = new Date();
		currentDate.setTime(currentDate.getTime() + (365 * 24 * 60 * 60 * 1000));
		const expires = "expires=" + currentDate.toUTCString();

	  document.getElementById("cookiesAccept").addEventListener("click", function() {
		document.cookie = "cookiesAccept=Yes" + expires + ";path=/";
		$('#cookiesModal').modal('hide')
	  });
	  document.getElementById("cookiesDecline").addEventListener("click", function() {
		document.cookie = "cookiesAccept=No" + expires + ";path=/";
		$('#cookiesModal').modal('hide')
	  });

}

/**
 * Init Template Components
 * only once after the initial
 * page load
 */
function initComponentsOnce({
	scope = window.$document,
	container = window.$pageWrapper
}) {
	window.theme.header = new Header();

	new LazyLoad({
		scope: window.$document,
		setPaddingBottom: true,
		run: true
	});
}
